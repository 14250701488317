import React from 'react'

import SEO from 'components/seo'
import Layout from 'components/layouts/layout'

import ComingSoon from 'static/images/coming-soon.jpg'

export default ({ location }) => {
  return (
    <Layout fullWidth={true} marginBottom={false} title="Franchise">
      <SEO title="Franchise" path={location.pathname} />

      <img src={ComingSoon} alt="This section is coming soon" />
    </Layout>
  )
}
